var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-holder" }, [
    _c("div", { staticClass: "message" }, [
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.isSuccesfullAndAccesscode
                ? "Thank you, you're now signed up!"
                : "Thank you for using the tool."
            ) +
            " "
        )
      ]),
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.isSuccesfullAndAccesscode
                ? ""
                : "Please enter your email address and details for continued access."
            ) +
            " "
        )
      ])
    ]),
    _vm.accessCode === null
      ? _c("div", { staticClass: "input-wrapper" }, [
          _c("div", { staticClass: "input-wrapper--name" }, [
            _c("div", { staticClass: "name-holder input-content" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.userDetails.firstname.$model,
                    expression: "$v.userDetails.firstname.$model"
                  }
                ],
                class: {
                  "error-input":
                    !_vm.$v.userDetails.firstname.required && _vm.$v.dirty
                },
                attrs: { placeholder: "First name" },
                domProps: { value: _vm.$v.userDetails.firstname.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.userDetails.firstname,
                      "$model",
                      $event.target.value
                    )
                  }
                }
              }),
              !_vm.$v.userDetails.firstname.required && _vm.$v.$dirty
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(" First name field is required ")
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "name-holder input-content" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.userDetails.lastname.$model,
                    expression: "$v.userDetails.lastname.$model"
                  }
                ],
                class: {
                  "error-input":
                    !_vm.$v.userDetails.lastname.required && _vm.$v.dirty
                },
                attrs: { placeholder: "Last name" },
                domProps: { value: _vm.$v.userDetails.lastname.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.userDetails.lastname,
                      "$model",
                      $event.target.value
                    )
                  }
                }
              }),
              !_vm.$v.userDetails.lastname.required && _vm.$v.$dirty
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(" Last name field is required ")
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "input-content" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.userDetails.company.$model,
                  expression: "$v.userDetails.company.$model"
                }
              ],
              class: {
                "error-input":
                  !_vm.$v.userDetails.company.required && _vm.$v.dirty
              },
              attrs: { type: "text", placeholder: "Company name" },
              domProps: { value: _vm.$v.userDetails.company.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.userDetails.company,
                    "$model",
                    $event.target.value
                  )
                }
              }
            }),
            !_vm.$v.userDetails.company.required && _vm.$v.$dirty
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" Company is required ")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "input-content" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.userDetails.email.$model,
                  expression: "$v.userDetails.email.$model"
                }
              ],
              class: {
                "error-input":
                  !_vm.$v.userDetails.email.required && _vm.$v.dirty
              },
              attrs: {
                type: "text",
                placeholder: "Your email e.g your@email.com"
              },
              domProps: { value: _vm.$v.userDetails.email.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.userDetails.email,
                    "$model",
                    $event.target.value
                  )
                }
              }
            }),
            !_vm.$v.userDetails.email.required && _vm.$v.$dirty
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" Email is required ")
                ])
              : _vm._e(),
            !_vm.$v.userDetails.email.email && _vm.$v.$dirty
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" Please enter a valid email ")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "terms-conditions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.termsAgreed,
                  expression: "userDetails.termsAgreed"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.userDetails.termsAgreed)
                  ? _vm._i(_vm.userDetails.termsAgreed, null) > -1
                  : _vm.userDetails.termsAgreed
              },
              on: {
                change: function($event) {
                  var $$a = _vm.userDetails.termsAgreed,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.userDetails,
                          "termsAgreed",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.userDetails,
                          "termsAgreed",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.userDetails, "termsAgreed", $$c)
                  }
                }
              }
            }),
            _c(
              "p",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("showTerms")
                  }
                }
              },
              [
                _vm._v("I consent to the "),
                _c("span", { staticClass: "terms-conditions--link" }, [
                  _vm._v(" Terms And Conditions ")
                ])
              ]
            ),
            _vm.showFormTermsError
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" Please agree to our T&Cs")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "terms-conditions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.marketingAgreed,
                  expression: "userDetails.marketingAgreed"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.userDetails.marketingAgreed)
                  ? _vm._i(_vm.userDetails.marketingAgreed, null) > -1
                  : _vm.userDetails.marketingAgreed
              },
              on: {
                change: function($event) {
                  var $$a = _vm.userDetails.marketingAgreed,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.userDetails,
                          "marketingAgreed",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.userDetails,
                          "marketingAgreed",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.userDetails, "marketingAgreed", $$c)
                  }
                }
              }
            }),
            _c("p", [_vm._v(" I agree to receiving marketing communications")]),
            _vm.showFormMarketingError
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" Please agree this condition")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "terms-conditions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.eeaAgreed,
                  expression: "userDetails.eeaAgreed"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.userDetails.eeaAgreed)
                  ? _vm._i(_vm.userDetails.eeaAgreed, null) > -1
                  : _vm.userDetails.eeaAgreed
              },
              on: {
                change: function($event) {
                  var $$a = _vm.userDetails.eeaAgreed,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.userDetails,
                          "eeaAgreed",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.userDetails,
                          "eeaAgreed",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.userDetails, "eeaAgreed", $$c)
                  }
                }
              }
            }),
            _c("p", [
              _vm._v(
                "I consent to my personal data being transferred outside of the EEA"
              )
            ]),
            _vm.showFormEeaError
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" Please agree this condition")
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "area-holder theme-button center-content" },
            [
              _c(
                "button",
                {
                  class: { "normal-state": !_vm.isSubmitting },
                  on: { click: _vm.submitUserEmail }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "button-icon",
                    class: { "button-icon--show": _vm.isSubmitting },
                    attrs: {
                      icon: [
                        _vm.isSubmitting ? "fad" : "fal",
                        _vm.isSubmitting ? "spinner-third" : "paper-plane"
                      ],
                      spin: _vm.isSubmitting
                    }
                  }),
                  _vm._v("Submit ")
                ],
                1
              )
            ]
          )
        ])
      : _vm._e(),
    _vm.isSuccesfullAndAccesscode
      ? _c("div", { staticClass: "access-code theme-button" }, [
          _c(
            "button",
            {
              staticClass: "button-icon",
              on: {
                click: function($event) {
                  return _vm.$emit("closeModalPopUp")
                }
              }
            },
            [_vm._v(" Close ")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }