var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c(
      "div",
      { staticClass: "goal-outline" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("img", {
            staticClass: "gbe-calculator",
            attrs: { src: "/GBE-Calculator-Logo-White.png" }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }