var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showTerms ? _c("TermsAndConditionsWrapper") : _vm._e(),
      _c("HeaderBar"),
      _c("main", [_c("router-view")], 1),
      _c("FooterBar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }