var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "terms-conditions-wrapper" }, [
    _c("div", { staticClass: "inner-wrapper" }, [
      _vm.showTerms
        ? _c("h3", [_vm._v(" GBE Calculator Terms And Conditions")])
        : _vm._e(),
      _vm.showTerms
        ? _c("div", { staticClass: "terms-content" }, [
            _c("h4", [_vm._v("Introduction to the GBE Calculator")]),
            _c("p", [
              _vm._v(
                "The GBE Calculator (also referred to as “this website”) is provided by Lane Clark & Peacock LLP (“LCP”) on behalf of itself and its collaborator, Analytics FC Limited."
              )
            ]),
            _c("h4", [_vm._v("Using the GBE Calculator")]),
            _c("p", [
              _vm._v(
                " Your use of the GBE Calculator is subject to these terms and conditions. Please read these and, if you do not agree to them, you must not use the GBE Calculator. "
              )
            ]),
            _c("p", [
              _vm._v(
                " We may amend these terms and conditions from time to time, and our right to do so is not subject to the consent of any other person. Please check them periodically so that you can be informed of any changes. "
              )
            ]),
            _c("p", [
              _vm._v(
                "These terms and conditions were last amended on 17 December 2021."
              )
            ]),
            _c("h4", [_vm._v("Liability and reliance on information")]),
            _c("p", [
              _vm._v(
                "Neither we nor AFC guarantees that this website, or any content on it, will be free from errors or omissions."
              )
            ]),
            _c("p", [
              _vm._v(
                " In particular, but without limitation to the foregoing, the calculations produced by this website are based on rules agreed by the English Football Association and the Home Office for players to play in England. There will be edge cases and different interpretations. The GBE Calculator outputs are estimates and should be used as a guide to assess the likelihood of a player meeting the criteria, rather than a definitive assessment. The GBE criteria are regularly reviewed by the English Football Association and the Home Office; we cannot guarantee that any changes to the criteria will be allowed for within our calculations immediately. There variations to the GBE criteria for players to play in Scotland, and details can be found on the Scottish Football Association’s website. "
              )
            ]),
            _c("p", [
              _vm._v(
                "Neither we nor AFC accepts any liability for any actions or decisions taken using information on this website."
              )
            ]),
            _c("p", [
              _vm._v(
                "To the extent permitted by law, each of LCP and AFC excludes any and all liability for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with use of, or inability to use, this website or use of or reliance on any content on this website."
              )
            ]),
            _c("h4", [_vm._v("Information about us")]),
            _c("p", [
              _vm._v(
                " This website is owned and operated by LCP, a limited liability partnership registered in England and Wales with registered number OC301436 and VAT number GB258363873. LCP is a registered trademark in the UK (Regd. TM No 2315442) and in the EU (Regd. TM No 002935583). All partners are members of LCP. A list of members' names is available for inspection at 95 Wigmore Street, London W1U1DQ, the firm's principal place of business and registered office. "
              )
            ]),
            _c("p", [
              _vm._v(
                "Lane Clark & Peacock LLP is authorised and regulated by the Financial Conduct Authority and is licensed by the Institute and Faculty of Actuaries for a range of investment business activities."
              )
            ]),
            _c("p", [
              _vm._v(
                "Locations in London, Winchester, Ireland, and - operating under licence - the Netherlands."
              )
            ]),
            _c("p", [_vm._v("© Lane Clark & Peacock LLP 2021")]),
            _c("p", [
              _vm._v(
                "https://www.lcp.uk.com/emails-important-information contains important information about this communication from LCP, including limitations as to its use."
              )
            ]),
            _c("p", [
              _vm._v(
                "LCP is providing this website on behalf of itself and AFC. AFC is a limited liability company registered in England and Wales with registered number 10185818 and registered office at 35 Locks Hill, Frome, Somerset, BA11 1NA."
              )
            ]),
            _c("h4", [_vm._v("Access to the GBE Calculator")]),
            _c("p", [
              _vm._v(
                "We may update, modify, restrict access to or close this website. Neither we nor AFC guarantees uninterrupted use of this website or accepts liability for any access delays or interruptions."
              )
            ]),
            _c("h4", [_vm._v("Definitions")]),
            _vm._m(0),
            _c("p"),
            _c("h4", [_vm._v("Intellectual Property Rights")]),
            _c("p", [
              _vm._v(
                "We are the owner or the licensee of all Intellectual Property Rights in this website and the Content."
              )
            ]),
            _c("p", [_vm._v("For these purposes:")]),
            _vm._m(1),
            _c("p"),
            _c("h4", [_vm._v("Online privacy")]),
            _c("p", [
              _vm._v(
                "The GBE Calculator Privacy Policy for Authorised Users sets out the basis on which we and AFC will use your personal data in connection with your use of this website."
              )
            ]),
            _c("p", [
              _vm._v(
                "In particular, please note that we use information that we collect from this website to send you marketing emails in connection with football analytics and football consultancy services provided by us and/or AFC."
              )
            ]),
            _c("h4", [_vm._v("Viruses and security")]),
            _c("p", [
              _vm._v(
                " Neither we nor AFC guarantees that this website will be free from bugs or viruses. You must use your own virus protection software. Neither we nor AFC accepts liability for any damage or loss caused by: "
              )
            ]),
            _vm._m(2),
            _c("p"),
            _c("p", [
              _vm._v(
                "You must not misuse this website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful."
              )
            ]),
            _c("p"),
            _c("h4", [_vm._v("Third party links")]),
            _c("p", [
              _vm._v(
                " With the exception of TransferLab, where this website contains links to other websites and resources provided by third parties, these links are provided for your information only. We do not endorse any of these websites or accept responsibility for their contents. "
              )
            ]),
            _c("h4", [_vm._v("No conditions, warranties or representations")]),
            _c("p", [
              _vm._v(
                "To the extent permitted by law, each of LCP and AFC excludes all conditions, warranties and representations in respect of this website and its content (whether express or implied)."
              )
            ]),
            _c("h4", [_vm._v("Other matters")]),
            _c("p", [
              _vm._v(
                "Nothing in these terms and conditions excludes or limits LCP or AFC’s liability for fraud or reckless disregard of professional obligations or for death or personal injury arising from our or AFC’s negligence or that of our respective partners, consultants or employees."
              )
            ]),
            _c("p", [
              _vm._v(
                "If any of these terms and conditions is held by any judicial or other competent authority to be void, voidable, illegal or otherwise unenforceable, the remaining terms and conditions shall remain in full force and effect."
              )
            ]),
            _c("p", [
              _vm._v(
                "Any temporary or permanent waiver on our part of any of the terms and conditions shall not affect our right subsequently to enforce all the terms and conditions."
              )
            ]),
            _c("p", [
              _vm._v(
                " Save as expressly stated otherwise, none of these terms and conditions is intended to be enforceable pursuant to the Contracts (Rights of Third Parties) Act 1999. Accordingly, no third party, save for AFC, shall have any right to enforce or rely on any of these terms and conditions. "
              )
            ]),
            _c("h4", [_vm._v("Governing law")]),
            _c("p", [
              _vm._v(
                "These terms and conditions shall be governed by and construed in accordance with English law, and we and you each submit to the exclusive jurisdiction of the English Courts."
              )
            ])
          ])
        : _vm._e(),
      _vm.showUserPolicy
        ? _c("h3", [_vm._v("Privacy Policy for Users")])
        : _vm._e(),
      _vm.showUserPolicy
        ? _c("div", { staticClass: "terms-content" }, [
            _c("h4", [_vm._v("About us")]),
            _c("p", [
              _vm._v(
                "This privacy policy is provided on behalf of Lane Clark & Peacock LLP (“LCP”) of 95 Wigmore Street, London, W1U 1DU, registered number OC301436."
              )
            ]),
            _vm._m(3),
            _c("h4", [_vm._v("Scope of this policy")]),
            _c("p", [
              _vm._v(
                " This privacy policy applies to https://gbe.lcp.uk.com. Save in the case of TransferLab, where links from this website are provided to non-LCP websites, LCP is not responsible for those websites. In the case of TransferLab, that website is the outcome of a collaboration between LCP and Analytics FC Ltd. Access to TransferLab is subject to separate documentation. "
              )
            ]),
            _c("h4", [_vm._v("Why do we collect information?")]),
            _c("p", [
              _vm._v(
                " The reason we collect information about you is to provide you with access to this website and for marketing purposes. Any information you provide will be used only by LCP, and will not be disclosed to any other party or for any other reason (other than as set out in this privacy policy) without your prior consent. "
              )
            ]),
            _c("h4", [_vm._v("What information do we gather?")]),
            _c("p", [
              _vm._v("We collect your name, email address and organisation.")
            ]),
            _c("h4", [
              _vm._v(
                "How do we use information that we collect from this website?"
              )
            ]),
            _c("p", [
              _vm._v(
                " Once a specified number of searches has been exceeded, this website can only be accessed by users who have confirmed their acceptance of each of the “tick box” statements presented to them (“authorised users”). LCP must retain a record of authorised users so that rights of access can be restricted to those individuals. "
              )
            ]),
            _c("p", [
              _vm._v(
                " This website is provided by LCP on behalf of itself and its collaborator, Analytics FC Limited (company number 10185818, of 35 Locks Hill, Frome, Somerset, BA11 1NA) (the “Collaborator”). LCP will share your information with the Collaborator. "
              )
            ]),
            _c("p", [
              _vm._v(
                " We use information that we collect from this website to send you marketing emails in connection with football analytics and football consultancy services provided by the Collaborator and / or LCP. "
              )
            ]),
            _c("h4", [_vm._v("Access")]),
            _c("p", [
              _vm._v(
                " Access to this website is only for authorised users (as defined above). If you have been given access by mistake or suspect that an unauthorised person has used your credentials to register, you must inform us promptly at support@analyticsfc.co.uk. "
              )
            ]),
            _c("h4", [_vm._v("Transfer outside of the EEA and Profiling")]),
            _c("p", [
              _vm._v(
                " We do not use your personal data to make automated decisions about you (including profiling). The Collaborator may transfer your data to another country or international organisation in such circumstances as it considers reasonably necessary for the operation of its business. "
              )
            ]),
            _c("h4", [_vm._v("Access to your information and correction")]),
            _c("p", [
              _vm._v(
                " You have a right to request access to and/or correction to your personal information. If you believe the data we hold on you is incorrect, or you wish to see the information we hold on you, please contact our Data Protection Officer (details below). "
              )
            ]),
            _c("h4", [_vm._v("Security")]),
            _c("p", [
              _vm._v(
                " Although we maintain appropriate technological and organisational security measures to protect the security of your data against loss, misuse, unauthorised access, disclosure or alteration, the security of the transmission of information via the internet cannot always be guaranteed. You acknowledge this in your use of our website. "
              )
            ]),
            _c("h4", [_vm._v("Data retention periods")]),
            _c("p", [
              _vm._v(
                "We will retain your personal data for so long as the purpose you have provided it for still exists unless a longer retention period is required or permitted by law."
              )
            ]),
            _c("h4", [_vm._v("Complaints")]),
            _c("p", [
              _vm._v(
                " We aim to meet the highest standards when collecting and using your personal information. However, if you believe we are not meeting these standards and wish to make a complaint, please contact our Data Protection Officer (details below). If you are not satisfied with our response you have the right to complain to the Information Commissioner’s Office: https://ico.org.uk/. "
              )
            ]),
            _c("h4", [_vm._v("Changes to our privacy policy")]),
            _c("p", [
              _vm._v(
                " We reserve the right, at our own exclusive discretion, to change, amend, add or remove any part of this privacy notice, at any time. In order to facilitate the review of any such changes, the policy will contain an indication of the date on which the policy was updated. We keep our privacy policy under regular review and place any updates on our website. "
              )
            ]),
            _c("h4", [_vm._v("Cookies")]),
            _c("p", [
              _vm._v(
                " We only use strictly necessary cookies on this website. These are cookies that are required for the operation of this website. They include cookies that are used to track whether you have reached the maximum number of searches and if so, whether you have provided your details. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Your browser will allow you to restrict, block or remove any cookies set by this website. Details of this functionality will vary by browser but can usually be found in the browser help section. The website www.aboutcookies.org contains more details about cookies and options for dealing with them. "
              )
            ]),
            _c("h4", [_vm._v("How to contact us?")]),
            _c("p", [
              _vm._v(
                "If you would like further information about our privacy policy or how to request your personal information you can email us at data.protection@lcp.uk.com or write to:"
              )
            ]),
            _c("p", [_vm._v("The Data Protection Officer")]),
            _c("p", [_vm._v("95 Wigmore Street")]),
            _c("p", [_vm._v("London")]),
            _c("p", [_vm._v("WU1 1DU")])
          ])
        : _vm._e(),
      _vm.showPlayerPolicy
        ? _c("h3", [_vm._v("Privacy Policy for Players")])
        : _vm._e(),
      _vm.showPlayerPolicy
        ? _c("div", { staticClass: "terms-content" }, [
            _c("h4", [_vm._v("About us")]),
            _c("p", [
              _vm._v(
                "This privacy policy is provided on behalf of Lane Clark & Peacock LLP (“LCP”) of 95 Wigmore Street, London, W1U 1DU, registered number OC301436."
              )
            ]),
            _c("p", [
              _vm._v(
                " LCP is registered with the Information Commissioner’s Office (“ICO”). Our registration number is Z6661882. For the purposes of Data Protection Law, your personal data is processed by us in our capacity data controller for our customers. "
              )
            ]),
            _vm._m(4),
            _c("h4", [_vm._v("Personal data that we may collect about you")]),
            _c("p", [
              _vm._v(
                "We may collect and process personal data our business partners provide to us in connection with our provision of the GBE Calculator tool."
              )
            ]),
            _c("p", [
              _vm._v(
                " Users of the website have the ability to see estimated Governing Body Endorsement (GBE) points for you, as calculated based on the rules agreed by the FA and Home Office for players to play in England. Further information displayed in the site includes your name, date of birth, current club, current league and your position. "
              )
            ]),
            _c("h4", [_vm._v("Why do we collect information?")]),
            _c("p", [
              _vm._v(
                "We process personal data for certain legitimate business purposes, which include some or all of the following:"
              )
            ]),
            _vm._m(5),
            _c("p"),
            _c("p", [
              _vm._v(
                " We process your personal data for these purposes because it is in both your and our users' interest to do so, and it enhances the services that we provide. The GBE Calculator tool helps users identify whether you are likely to be eligible to play in England, making it easier for users to determine if you are suitable to play for potential future employers in England. We do not process your personal data where to do so would prejudice your fundamental rights and freedoms. "
              )
            ]),
            _c("h4", [_vm._v("Retention of your personal data")]),
            _c("p", [
              _vm._v(
                "We will retain your personal data for so long as the purpose it was collected for still exists unless a longer retention period is required or permitted by law."
              )
            ]),
            _c("h4", [_vm._v("Your rights in respect of your personal data")]),
            _c("p", [_vm._v("You have the right to:")]),
            _vm._m(6),
            _c("p"),
            _c("p", [
              _vm._v(
                " If you wish to exercise any of these rights, please contact us using the contact details listed at the bottom of this privacy policy. We will aim to respond to any request received from you within one month of receipt. "
              )
            ]),
            _c("h4", [_vm._v("Transfer outside of the EEA and Profiling")]),
            _c("p", [
              _vm._v(
                "We may transfer your data to another country or international organisation in such other circumstances as we consider reasonably necessary for the operation of our business, provided that we ensure that any such transfer complies with Chapter 5 of the GDPR (or any equivalent requirement in Data Protection Law)."
              )
            ]),
            _c("h4", [_vm._v("Automated decision making")]),
            _c("p", [
              _vm._v(
                "There is no automated decision-making as data subjects are not subject to decisions made exclusively on the basis of the automated data processing, including profiling, and which produces legal effects concerning them or which significantly affects them in a similar way."
              )
            ]),
            _c("h4", [_vm._v("How to contact us?")]),
            _c("p", [
              _vm._v(
                "If you would like further information about our privacy policy or how to request your personal information you can email us at data.protection@lcp.uk.com or write to:"
              )
            ]),
            _c("p", [_vm._v("The Data Protection Officer")]),
            _c("p", [_vm._v("95 Wigmore Street")]),
            _c("p", [_vm._v("London")]),
            _c("p", [_vm._v("WU1 1DU")])
          ])
        : _vm._e(),
      _c("button", { staticClass: "btn", on: { click: _vm.closeTerms } }, [
        _vm._v("Close")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", [_vm._v("References to “we”, “us” or “our” are to LCP.")])
      ]),
      _c("li", [
        _c("p", [
          _vm._v("References to “you” or “your” are to a user of this website.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", [
          _vm._v(
            "“Content” means any text, software, database, format, graphic, program code, calculation, formula, model, chart and written work and all other materials developed by, or on behalf of, us and/or AFC which form part of this website; and"
          )
        ])
      ]),
      _c("li", [
        _c("p", [_vm._v("“Intellectual Property Rights” means:")]),
        _c("ul", [
          _c("li", [
            _c("p", [
              _vm._v(
                "copyright, patents, database rights and rights in trademarks, designs, know-how and confidential information (whether registered or not);"
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _vm._v(
                "applications for registration and the right to apply for registration for any of these rights; and"
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _vm._v(
                "all other intellectual property rights and equivalent or similar forms of protection existing anywhere in the world."
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", [
          _vm._v(
            "any virus, malicious code or software, defect or malfunction in connection with use of this website; or"
          )
        ])
      ]),
      _c("li", [
        _c("p", [
          _vm._v(
            "any breach of security or unauthorised use of this website arising from hacking or otherwise."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " LCP is registered with the Information Commissioner’s Office (“ICO”). Our registration number is Z6661882. For the purposes of Data Protection Law, your personal data is processed by us in our capacity data controller for our customers. Analytics FC Limited (our collaborator, as further described below) is also a data controller; you can find their privacy notice "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://analyticsfc.co.uk/privacy-policy/",
            target: "_blank"
          }
        },
        [_vm._v("here")]
      ),
      _vm._v(". ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "Analytics FC Limited (our collaborator, as further described below) is also a data controller; you can find their privacy notice "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://analyticsfc.co.uk/privacy-policy/",
            target: "_blank"
          }
        },
        [_vm._v("here")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", [
          _vm._v(
            "To provide the GBE Calculator to our clients and other users."
          )
        ])
      ]),
      _c("li", [
        _c("p", [_vm._v("For any other purpose required or permitted by law.")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", [_vm._v("a copy of your personal data that we hold;")])
      ]),
      _c("li", [
        _c("p", [
          _vm._v("have your data corrected if it is inaccurate or incomplete;")
        ])
      ]),
      _c("li", [
        _c("p", [
          _vm._v(
            "have your data deleted or removed if it is no longer necessary for the purpose for which it was obtained;"
          )
        ])
      ]),
      _c("li", [
        _c("p", [
          _vm._v(
            "request that the way in which we use your data is restricted to certain uses which you may specify;"
          )
        ])
      ]),
      _c("li", [
        _c("p", [
          _vm._v(
            "object to us claiming that we have a legitimate interest in processing your data; and"
          )
        ])
      ]),
      _c("li", [
        _c("p", [
          _vm._v(
            "request that we provide your personal data that we hold to you, so that you may reuse your data for your own purposes."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }