var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "home" },
    [
      _vm.showModal
        ? _c("base-modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("UserDetailsCapture", {
                        on: {
                          closeModalPopUp: _vm.closeModalPopUp,
                          showTerms: _vm.showTerms
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "terms",
                  fn: function() {
                    return [
                      _vm.showTermsAndConditions
                        ? _c("TermsAndConditionsWrapper")
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2658409150
            )
          })
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "homepage-message",
            class: { "hide-message": _vm.showSearchWithResults }
          },
          [
            _vm._v(
              " Search our extensive player database to find out if a footballer is likely to be eligible to play in the UK. "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "search",
            class: { "search-with-results": _vm.showSearchWithResults }
          },
          [
            _c("div", { staticClass: "search-area area-holder theme-button" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchString,
                    expression: "searchString",
                    modifiers: { trim: true }
                  }
                ],
                ref: "inputRef",
                staticClass: "input-field",
                attrs: { placeholder: "Search player e.g. Aubameyang" },
                domProps: { value: _vm.searchString },
                on: {
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchForPlayer.apply(null, arguments)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchString = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "normal-state",
                  on: { click: _vm.searchForPlayer }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "button-icon",
                    attrs: { icon: ["fal", "search"] }
                  }),
                  _vm._v("Search players ")
                ],
                1
              )
            ]),
            _vm._m(0),
            _vm.searchData && _vm.searchData.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "details",
                    class: { "details-results": _vm.searchData }
                  },
                  _vm._l(_vm.searchData, function(playerData, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "details-display",
                        on: {
                          click: function($event) {
                            return _vm.getPlayerDetails(playerData)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "details-display-image" }, [
                          _c("img", {
                            attrs: {
                              src:
                                "https://faimages.lcp.uk.com/players/" +
                                playerData.id +
                                ".png"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "details-display-info" }, [
                          _c("div", { staticClass: "info-name" }, [
                            _vm._v(" " + _vm._s(playerData.name) + " ")
                          ]),
                          _c("div", { staticClass: "info-club" }, [
                            _c("p", [_vm._v("Club:")]),
                            _c("p", [_vm._v(_vm._s(playerData.club))])
                          ]),
                          _c(
                            "div",
                            { staticClass: "details-display-image club-logo" },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://faimages.lcp.uk.com/clubs/" +
                                    playerData.clubId +
                                    ".png"
                                }
                              })
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.searchData && _vm.searchData.length < 1
              ? _c("div", { staticClass: "no-results" }, [
                  _vm._v(" No results found ")
                ])
              : _vm._e(),
            _vm.isFetching
              ? _c(
                  "div",
                  { staticClass: "loading-wrapper" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "loading-wrapper-icon",
                      attrs: { spin: "", icon: ["fad", "spinner-third"] }
                    }),
                    _c("span", [_vm._v("Loading ...")])
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "halfway",
          class: { "hide-center-circle": _vm.showSearchWithResults }
        },
        [
          _c("div", { staticClass: "halfway-line" }),
          _c("div", { staticClass: "halfway-center-circle" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "search-powered-by" }, [
      _c("span", [_vm._v("Powered by:")]),
      _c(
        "a",
        {
          attrs: {
            href: "https://analyticsfc.co.uk/transferlab/",
            target: "_blank"
          }
        },
        [_c("img", { attrs: { src: "/TransferLab-White.png" } })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }