var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", [
      _vm._v(
        " All content © copyright " +
          _vm._s(new Date().getFullYear()) +
          " Lane Clark & Peacock LLP. All rights reserved. "
      )
    ]),
    _c("div", [
      _c("span", { staticClass: "terms-link", on: { click: _vm.showTerms } }, [
        _vm._v("Terms and Conditions")
      ]),
      _c(
        "span",
        { staticClass: "terms-link", on: { click: _vm.showUserPolicy } },
        [_vm._v("User Privacy Policy")]
      ),
      _c(
        "span",
        { staticClass: "terms-link", on: { click: _vm.showPlayerPolicy } },
        [_vm._v("Player Privacy Policy")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }